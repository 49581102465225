import React from "react";

import PriceEditor from "./PriceEditor";

const PricesEditor = ({ priceTypes, prices, onChange }) => {

  const onPriceChange = (price) => {
    onChange({
      ...prices,
      [price.type]: price,
    });
  };

  return (
    <div className="prices">
      {Object.entries(prices).map(([_, price]) => (
          <PriceEditor key={_} priceTypes={priceTypes} price={price} onChange={onPriceChange} />
        ))} 
    </div>
  );
};

export default PricesEditor;
