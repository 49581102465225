import React from "react";
import { Button, Modal } from "react-bootstrap";

const CustomModal = ({ title, children, onConfirm, onClose, show }) => {
  const handleClose = () => {
    onClose();
  };


  return (
    <Modal show={show} onHide={() => handleClose}>
      <Modal.Header >
        <Modal.Title >{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>

      {onConfirm && onClose && (
        <Modal.Footer>
          <Button variant="secondary" onClick={onClose}>
            Cancelar
          </Button>
          <Button variant="danger" onClick={onConfirm}>
            Confirmar
          </Button>
        </Modal.Footer>
      )}

      {!onConfirm && onClose && (
        <Modal.Footer>
          <Button variant="danger" onClick={onClose}>
            OK
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default CustomModal;
