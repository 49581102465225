import React from 'react';

const LoadingSpinner = () => {
  const spinnerStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh', // O ajusta la altura según tus necesidades
  };

  const innerSpinnerStyle = {
    border: '4px solid rgba(0, 0, 0, 0.1)',
    borderTop: '4px solid #3498db', // Cambia el color según tus preferencias
    borderRadius: '50%',
    width: '40px',
    height: '40px',
    animation: 'spin 1s linear infinite',
    // Incluye aquí tus estilos de animación
    animationName: 'spin',
  };

  return (
    <div style={spinnerStyle}>
      <div style={innerSpinnerStyle}></div>
      {/* Agrega el @keyframes directamente aquí */}
      <style>
        {`
          @keyframes spin {
            to {
              transform: rotate(360deg);
            }
          }
        `}
      </style>
    </div>
  );
};

export default LoadingSpinner;