import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import ProductEditor from "./ProductEditor";
import { useState, useEffect } from "react";

const ProductsEditor = ({
  products,
  priceTypes,
  uploadImage,
  langs,
  updateProduct,
  deleteProduct,
  category,
  updateProductsOrder
}) => {
  const [localProducts, setLocalProducts] = useState(products);

  useEffect(() => {
    // Actualiza el estado local cuando cambian las propiedades (productos)
    setLocalProducts(products);
  }, [products]);

  const onDragEnd = (result) => {
    // Verifica si la operación de arrastrar y soltar fue completada
    if (!result.destination) {
      return;
    }

    // update position of products 
    const newProducts = Array.from(localProducts);
    const [removed] = newProducts.splice(result.source.index, 1);
    newProducts.splice(result.destination.index, 0, removed);

    // Actualiza el estado local solo si hay cambios en el orden
    if (!arraysEqual(localProducts, newProducts)) {
      setLocalProducts(newProducts);
      updateProductsOrder(category,newProducts)
    }
  };

  const arraysEqual = (arr1, arr2) => {
    return JSON.stringify(arr1) === JSON.stringify(arr2);
  };


  return (
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="products" type="PRODUCT">
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {localProducts.map((product, index) => (
               <Draggable key={product.id} draggableId={product.id} index={index}>
               {(provided) => (
                 <div ref={provided.innerRef} {...provided.draggableProps}>
                   <ProductEditor
                     langs={langs}
                     product={product}
                     priceTypes={priceTypes}
                     onProductUpdated={updateProduct}
                     deleteProduct={deleteProduct}
                     uploadImage={uploadImage}
                     providedDragHandle={provided.dragHandleProps}
                   />
                 </div>
               )}
             </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

  );
};

export default ProductsEditor;
