import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useCallback,
} from "react";
import { v4 as uuidv4 } from "uuid";
import {
  storage,
  ref,
  getDownloadURL,
  uploadBytes,
  uploadBytesResumable,
} from "../firebase";
import imageCompression from "browser-image-compression";

const MenuContext = createContext();

const normalizeProduct = (category, product) => {
  if (product.price && !product.prices) {
    product.prices = Object.entries(category.priceTypes).reduce(
      (acc, [key, value]) => {
        acc[key] = { type: key, value: product.price };
        return acc;
      },
      {}
    );
    delete product.price;
  } else if (product.prices && Array.isArray(product.prices)) {
    product.prices = Object.entries(category.priceTypes).reduce(
      (acc, [key, value]) => {
        acc[key] = {
          type: key,
          value: product.prices.find((price) => {
            if (price.type === key) {
              return price;
            }
          })?.value,
        };
        return acc;
      },
      {}
    );
  }
  return product;
};

const normalizeCategory = (category) => {
  if (category.name !== undefined) {
    if (!category.info) {
      category.info = {
        es: {
          name: category.name.es,
          description: category.description?.es,
        },
        en: {
          name: category.name.en,
          description: category.description?.en,
        },
      };
    }
    delete category.name;
  }

  if (!category.priceTypes) {
    const name = category.info?.es?.name;

    if (name?.startsWith("Cervezas")) {
      category.priceTypes = {
        ["chica"]: {
          name: "Chica",
        },
        ["mediana"]: {
          name: "Mediana",
        },
        ["grande"]: {
          name: "Grande",
        },
      };
    } else if (name?.startsWith("categoria")) {
      category.priceTypes = {
        ["simple"]: {
          name: "Simple",
        },
        ["doble"]: {
          name: "Doble",
        },
      };
    } else {
      category.priceTypes = {
        ["default"]: {
          name: "Precio",
        },
      };
    }
  }

  category.products = (category.products || []).map((product) =>
    normalizeProduct(category, product)
  );
  if (category.categories) {
    category.categories = category.categories.map(normalizeCategory);
  }
  return category;
};

const normalizeMenu = (menu) => {
  menu.languages = menu.languages || ["es", "en"];
  menu.categories = (menu.categories || []).map(normalizeCategory);

  return menu;
};

const MenuProvider = ({ user, menuId, children }) => {
  const [menu, setMenu] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [menuChanged, setMenuChanged] = useState(false);
  const [viewUrl, setViewurl] = useState();

  const loadMenu = useCallback(async () => {
    setIsLoading(true);
    const menuName = menuId.toLowerCase().replace(/\s/g, "-");
    const menuFile = `menu/${user.uid}/${menuName}.json`;

    try {
      const fileRef = ref(storage, menuFile);
      const downloadURL = await getDownloadURL(fileRef);
      const response = await fetch(downloadURL);
      const menuData = await response.json();


      const viewName = menuData.title.toLowerCase().replace(/\s/g, "-");
      const viewRef = ref(storage, `menu/${user.uid}/${viewName}.html`);
      try {
        const Url = await getDownloadURL(viewRef);
        setViewurl(Url)
      } catch (error) {
        console.log("ERROR AL OBTENER LA URL DEL MENÚ:", error);
      }
      
      setMenu(normalizeMenu(menuData));
      setIsLoading(false);
    } catch (error) {
      console.error("Error al cargar el menú:", error);
      setIsLoading(false);
    }


  }, [user, menuId]);
  

  useEffect(() => {
    if (user && menuId) {
      loadMenu();
    }

    return () => {};
  }, [user, menuId, loadMenu]);

  const updateProduct = (category, product) => {
    category.products = category.products.map((p) => {
      if (p.id === product.id) {
        return product;
      }
      return p;
    });
    setMenu({ ...menu });
    setMenuChanged(true);
  };

 
  const updateProductsOrder = (category, products) => {
    category.products = products;
    setMenu({ ...menu });
    setMenuChanged(true);
  }

  const updateCategoriesOrder = (parent, categories) => {
    parent.categories = categories;
    setMenu({ ...menu });
    setMenuChanged(true);
  }
  
   // function to update subcategories order 
   const updateSubcategoriesOrder = (category, categories) => {
    category.categories = categories;
    setMenu({ ...menu });
    setMenuChanged(true);
  }



  const deleteProduct = (category, product) => {
    category.products = category.products.filter((p) => p.id !== product.id);
    setMenu({ ...menu });
    setMenuChanged(true);
  };

  const newProduct = (category) => {
    const newProduct = {
      id: uuidv4(),
      disabled: false,
      prices: Object.entries(category.priceTypes).reduce((acc, [key]) => {
        acc[key] = { type: key };
        return acc;
      }, {}),
    };

    category.products.push(newProduct);
    setMenu({ ...menu });
    setMenuChanged(true);
  };

  const updateCategory = (parent, category) => {
    parent.categories = parent.categories.map((c) => {
      if (c.id === category.id) {
        return category;
      }
      return c;
    });
    setMenu({ ...menu });
    setMenuChanged(true);
  };

  const deleteCategory = (parent, category) => {
    parent.categories = parent.categories.filter((c) => c.id !== category.id);
    setMenu({ ...menu });
    setMenuChanged(true);
  };

  const newCategory = (parent) => {
    const newCategory = {
      id: uuidv4(),
      products: [],
      categories: [],
      priceTypes: [
        {
          id: "default",
          name: "Precio",
        },
      ],
    };

    parent.categories = parent.categories || [];
    parent.categories.push(newCategory);
    setMenu({ ...menu });
    setMenuChanged(true);
  };

  const newCategoryPriceType = (category) => {
    // max of 3 price types
    if (Object.keys(category.priceTypes).length >= 3) {
      alert("No se pueden agregar más tipos de precio.");
      return;
    }
 
    const type = uuidv4();
    category.priceTypes = {
      ...category.priceTypes,
      [type]: {
        name: "Nuevo precio",
      },
    };
    category.products = category.products.map((product) => {
      product.prices = {
        ...product.prices,
        [type]: {
          type: type,
        },
      };
      return product;
    });
    setMenu({ ...menu });
    setMenuChanged(true);
  };

  const deleteCategoryPriceType = (category, priceType) => {
    // if the category has only 1 price type, dont deleteit
    if (Object.keys(category.priceTypes).length <= 1) {
      alert("No se puede eliminar el precio por defecto.");
      return;
    }

    delete category.priceTypes[priceType.id];

    category.products = category.products.map((product) => {
      return {
        ...product,
        prices: Object.entries(product.prices).reduce((acc, [key, value]) => {
          if (key !== priceType.id) {
            acc[key] = value;
          }
          return acc;
        }, {}),
      };
    });

    if (Object.keys(category.priceTypes).length === 1) {
      const remainingPriceTypeId = Object.keys(category.priceTypes)[0];
      category.priceTypes[remainingPriceTypeId].name = "Precio";
    }
    
    setMenu({ ...menu });
    setMenuChanged(true);
  };

  const saveMenu = async () => {
    const menuName = menuId.toLowerCase().replace(/\s/g, "-");
    const fileRef = ref(storage, `menu/${user.uid}/${menuName}.json`);
    const menuData = JSON.stringify(menu);
    const blob = new Blob([menuData], { type: "application/json" });

    try {
      await getDownloadURL(fileRef);

      uploadBytes(fileRef, blob)
        .then((snapshot) => {
          alert("El menú se sobrescribió correctamente!");
          setMenuChanged(false);
        })
        .catch((error) => {
          console.error("Error al sobrescribir el archivo JSON:", error);
        });
    } catch (error) {
      console.error("Menú no encontrado. No se puede sobrescribir.");
      alert("Menú no encontrado. No se puede sobrescribir.");
    }
  };

  const uploadImage = async (imageFile, setUploadProgress) => {
    const allowedExtensions = ["jpg", "jpeg", "png"];
    const allowedMimeTypes = ["image/jpeg", "image/png"];
  
    const fileExtension = imageFile.name.split(".").pop().toLowerCase();
    const fileType = imageFile.type.toLowerCase();
  
    if (
      !allowedExtensions.includes(fileExtension) ||
      !allowedMimeTypes.includes(fileType)
    ) {
      throw new Error("El archivo seleccionado no es una imagen admitida.");
    }
  
    return new Promise(async (resolve, reject) => {
      try {
        const compressedFile = await imageCompression(imageFile, {
          maxSizeMB: 0.09765625, // 100 KB
        });
  
        const fileRef = ref(
          storage,
          `menu/${user.uid}/menu-images/${compressedFile.name}`
        );
  
        const uploadTask = uploadBytesResumable(fileRef, compressedFile);
        setUploadProgress(0)
  
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100 ;
            setUploadProgress(progress);
          },
          (error) => {
            console.error("Error al subir la imagen:", error);
            reject(
              new Error(
                "Error al subir la imagen. Por favor, intenta nuevamente."
              )
            );
          },
          async () => {
            setTimeout(() => {
              setUploadProgress(0);
            }, 500);
            try {
              const imageUrl = await getDownloadURL(uploadTask.snapshot.ref);
              resolve(imageUrl);
            } catch (error) {
              console.error("Error al obtener la URL de descarga:", error);
              reject(
                new Error(
                  "Error al obtener la URL de descarga. Por favor, intenta nuevamente."
                )
              );
            }
          }
        );
      } catch (error) {
        console.error("Error al comprimir la imagen:", error);
        reject(
          new Error(
            "Error al comprimir la imagen. Por favor, intenta nuevamente."
          )
        );
      }
    });
  };

  return (
    <MenuContext.Provider
      value={{
        updateSubcategoriesOrder,
        updateProductsOrder,
        updateProduct,
        deleteProduct,
        newProduct,
        updateCategory,
        deleteCategory,
        newCategory,
        newCategoryPriceType,
        deleteCategoryPriceType,
        uploadImage,
        langs: menu?.languages,
      }}
    >
      {children(menu, menuChanged, isLoading, newCategory, saveMenu, updateCategoriesOrder,updateSubcategoriesOrder,viewUrl)}
    </MenuContext.Provider>
  );
};

const useMenuContext = () => {
  return useContext(MenuContext);
};

export { MenuProvider, useMenuContext };
