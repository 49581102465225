import React from "react";
import { Modal, Button } from "react-bootstrap";
import { useForm, ValidationError } from "@formspree/react";

const ContactModal = ({ showModal, handleClose }) => {
  const [state, handleSubmit] = useForm("xjvqqvrq");

  return (
    <Modal show={showModal} onHide={handleClose} centered>
      <div className="modal-content">
        <Modal.Header closeButton>
          <Modal.Title>
            Contactanos para más información o para obtener tu usuario
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {state.succeeded ? (
            <div className="alert alert-success" role="alert">
              ¡El mensaje se ha enviado con éxito!
            </div>
          ) : (
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <label htmlFor="name" className="form-label">
                  Nombre
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  name="name"
                  placeholder="Ingresa tu nombre"
                />
                <ValidationError
                  prefix="Nombre"
                  field="name"
                  errors={state.errors}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="email" className="form-label">
                  Correo electrónico
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  name="email"
                  placeholder="Ingresa tu correo electrónico"
                />
                <ValidationError
                  prefix="Correo electrónico"
                  field="email"
                  errors={state.errors}
                />
              </div>
              <div className=" message-group">
                <label htmlFor="message" className="form-label">
                  Mensaje
                </label>
                <textarea
                  className="form-control"
                  name="message"
                  rows="3"
                  placeholder="Ingresa tu mensaje"
                  style={{ resize: "none" }}
                />
                <ValidationError
                  prefix="Mensaje"
                  field="message"
                  errors={state.errors}
                />
              </div>
              <Button variant="primary" type="submit" disabled={state.submitting}>
                Enviar Mensaje
              </Button>
            </form>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cerrar
          </Button>
        </Modal.Footer>
      </div>
    </Modal>
  );
};

export default ContactModal;
