import {
  getAuth,
  onAuthStateChanged,
  signInWithRedirect,
  GoogleAuthProvider,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import { setUser, clearUser } from "../features/user/userSlice";

const auth = getAuth();
const provider = new GoogleAuthProvider();

// export const signIn = async (dispatch) => {
//   try {
//     await signInWithRedirect(auth, provider);
//   } catch (error) {
//     console.log(error);
//   }
// };

export const signInWithEmailPassword = async (email, password, dispatch) => {
  try {
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    const user = userCredential.user;
    dispatch(setUser({ uid: user.uid, name: user.displayName }));
  } catch (error) {
    console.error("Error al iniciar sesión con correo y contraseña:", error);
  }
};

export const signOutUser = async (dispatch) => {
  try {
    await signOut(auth);
    dispatch(clearUser());
  } catch (error) {
    console.log(error);
  }
};

export const initAuthStateListener = (dispatch) => {
  return onAuthStateChanged(auth, (user) => {
    if (user) {
      dispatch(setUser({ uid: user.uid, name: user.displayName }));
    } else {
      dispatch(clearUser());
    }
  });
};

export const getCurrentUser = async () => {
  try {
    const user = await auth.currentUser;
    return user;
  } catch (error) {
    console.error("Error al obtener el usuario actual:", error);
    return null;
  }
};
