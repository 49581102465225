import { Link, Navigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectUser } from "../features/user/userSlice";
import { Button } from "react-bootstrap";
import { BiPlus } from "react-icons/bi";
import CategoriesEditor from "../components/categories/CategoriesEditor";
import { MenuProvider } from "../contexts/ContextProvider";
import LoadingSpinner from "../components/LoadingSpinner";

const MenuEditor = () => {
  const { menuId } = useParams();
  const user = useSelector(selectUser);

  if (!user) {
    console.log("NO HAY USUARIO AUTENTICADO");
    // Si no hay usuario, puedes redirigir a la página de inicio de sesión u otra acción.
    return <Navigate to="/aaaa" replace />;
  }

  return (
    <MenuProvider user={user} menuId={menuId}>
      {(
        menu,
        menuChanged,
        loading,
        newCategory,
        saveMenu,
        updateCategoriesOrder,
        updateSubcategoriesOrder,
        viewUrl
      ) => {
        return (
          <div className="categories-container">
            {loading ? (
              <LoadingSpinner />
            ) : (
              <div>
                <div className="menu-title">{menu?.title}</div>
                <br />
                <Link target="blank" to={`${viewUrl}`} className="view-url btn">
                  Ver Menú
                </Link>
                {menuChanged && (
                  <button onClick={saveMenu} className="upload-menu">
                    Actualizar <br /> Menú
                  </button>
                )}

                {menu && menu.categories && (
                  <CategoriesEditor
                    level={0}
                    parent={menu}
                    updateCategoriesOrder={updateCategoriesOrder}
                    updateSubcategoriesOrder={updateSubcategoriesOrder}
                  />
                )}

                <Button
                  onClick={() => newCategory(menu)}
                  variant=""
                  className="add-button"
                >
                  <BiPlus />
                  Categoria
                </Button>
              </div>
            )}
          </div>
        );
      }}
    </MenuProvider>
  );
};

export default MenuEditor;
