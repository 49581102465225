import { LANGUAGES } from "../constants";

import React, { useState } from "react";

const InfoEditor = ({ info, onChange, lang, isCollapsed }) => {
  const [nameInput, setNameInput] = useState({
    value: info?.name || "",
    cursorPosition: 0,
  });

  const [descriptionInput, setDescriptionInput] = useState({
    value: info?.description || "",
    cursorPosition: 0,
  });

  const onChangeName = (event) => {
    const inputValue = event.target.value;
    const newPosition = event.target.selectionStart || 0;

    setNameInput({ value: inputValue, cursorPosition: newPosition });
    onChange({ ...info, name: inputValue });
  };

  const onChangeDescription = (event) => {
    const inputValue = event.target.value;
    const newPosition = event.target.selectionStart || 0;

    setDescriptionInput({
      value: inputValue,
      cursorPosition: newPosition,
    });
    onChange({ ...info, description: inputValue });
  };

  return (
    <div className={`product-info ${isCollapsed ? "collapsed" : ""}`}>
      <p>{LANGUAGES[lang]?.name}</p>

      <>
        <div className="product-info-div">
          <span>Nombre</span>
          <input
            className="product-name-input-ref"
            type="text"
            value={nameInput.value}
            placeholder="Agrega un nombre"
            onChange={onChangeName}
            selectionStart={nameInput.cursorPosition}
            selectionEnd={nameInput.cursorPosition}
          />
        </div>
        <div className="product-info-div">
          <span>Descripción</span>
          <input
            className="product-description-input-ref"
            type="text"
            value={descriptionInput.value}
            placeholder="Agrega una descripción"
            onChange={onChangeDescription}
            selectionStart={descriptionInput.cursorPosition}
            selectionEnd={descriptionInput.cursorPosition}
          />
        </div>
      </>
    </div>
  );
};

export default InfoEditor;
