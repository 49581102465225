export const LANGUAGES = {
  es: { name: "Español" },
  en: { name: "Inglés" },
};

export const PRICE_TYPES = {
  default: {
    name: "Precio",
    },
  simple: {
    name: "Simple",
  },
  doble: {
    name: "Doble",
  },
  chica: {
    name: "Chica",
  },
  mediana: {
    name: "Mediana",
  },
  grande: {
    name: "Grande",
  },
};
