import React, { useState } from "react";
import { Button, Container, Modal } from "react-bootstrap";
import ProductsEditor from "../products/ProductsEditor";
import CategoriesEditor from "./CategoriesEditor";
import { BiPlus, BiTrash, BiChevronDown, BiChevronsUp } from "react-icons/bi";
import { useMenuContext } from "../../contexts/ContextProvider";
import InfosEditor from "../InfosEditor";
import PriceTypesEditor from "./PriceTypesEditor";
import CustomModal from "../CustomModal";

const CategoryEditor = ({ level, parent, category, providedDragHandle }) => {
  const {
    updateCategory,
    deleteCategory,
    newCategory,
    newCategoryPriceType,
    deleteCategoryPriceType,
    updateProduct,
    deleteProduct,
    newProduct,
    uploadImage,
    langs,
    updateProductsOrder,
  } = useMenuContext();

  const [showConfirmation, setShowConfirmation] = React.useState(false);
  const [isCollapsed, setIsCollapsed] = useState(true);

  const handleClose = () => setShowConfirmation(false);
  const handleShow = () => setShowConfirmation(true);

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  const onChangeInfo = (info) => {
    updateCategory(parent, {
      ...category,
      info,
    });
  };

  const onChangePriceTypes = (priceTypes) => {
    updateCategory(parent, {
      ...category,
      priceTypes,
    });
  };

  const _updateProduct = (product) => {
    updateProduct(category, product);
  };

  const _deleteProduct = (product) => {
    deleteProduct(category, product);
  };

  const handleDeleteCategory = () => {
    deleteCategory(parent, category);
    handleClose();
  };

  return (
    <Container
      className={`category-editor level-${level} ${
        isCollapsed ? "collapsed" : ""
      }`}
    >
      <div className="category-top-buttons">
        <div className="drag-handle" {...providedDragHandle}>
          {/* Puedes personalizar este elemento con tu propio ícono o texto */}
          <div className="drag-handle-icon-category">⋮⋮⋮</div>
        </div>
        <div className="collapse-toggle" onClick={toggleCollapse}>
          {isCollapsed ? <BiChevronDown /> : <BiChevronsUp />}
        </div>
      </div>
      {isCollapsed && (
        <div className="category-name-collapsed">
          {category.info?.es?.name || "Categoria sin nombre aún"}
        </div>
      )}

      {!isCollapsed && (
        <>
          <div className="menu-col-title category-info">
            {langs && (
              <InfosEditor
                langs={langs}
                info={category.info}
                onChange={onChangeInfo}
                productId={category.id}
                isCollapsed={isCollapsed}
              />
            )}
            {category.priceTypes && (
              <PriceTypesEditor
                priceTypes={category.priceTypes}
                onChange={onChangePriceTypes}
                onAdd={() => newCategoryPriceType(category)}
                onDelete={(priceType) =>
                  deleteCategoryPriceType(category, priceType)
                }
              />
            )}
          </div>

          {!isCollapsed && category.products && (
            <ProductsEditor
              updateProductsOrder={updateProductsOrder}
              category={category}
              langs={langs}
              products={category.products}
              priceTypes={category.priceTypes}
              uploadImage={uploadImage}
              updateProduct={_updateProduct}
              deleteProduct={_deleteProduct}
            />
          )}

          {!isCollapsed && (
            <div>
              <Button
                onClick={() => newProduct(category)}
                variant=""
                className="add-button-product"
              >
                <BiPlus />
                Producto
              </Button>
              {category.categories && (
                <CategoriesEditor level={level + 1} parent={category} />
              )}
            </div>
          )}

          {level === 0 && (
            <Button
              onClick={() => newCategory(category)}
              variant=""
              className="add-button"
            >
              <BiPlus />
              Sub Categoría
            </Button>
          )}
        </>
      )}
      <Button
        className={`trash ${isCollapsed ? "collapsed" : ""}`}
        onClick={handleShow}
        variant="danger"
      >
        <BiTrash className="trash-icon" />
        <span className="delete-category-text">
          {level === 0 ? "Eliminar Categoría" : "Eliminar Subcategoría"}
        </span>
      </Button>
      <CustomModal
        show={showConfirmation}
        title="Confirmar Eliminación"
        onConfirm={handleDeleteCategory}
        onClose={() => setShowConfirmation(false)}
      >
        {level === 0
          ? "¿Estás seguro que deseas eliminar esta categoría?"
          : "¿Estás seguro que deseas eliminar esta subcategoría?"}
      </CustomModal>
    </Container>
  );
};

export default CategoryEditor;
