import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { BsUpload, BsArrowsAngleExpand, BsTrash } from "react-icons/bs";

const ImageEditor = ({ product, onProductUpdated, uploadImage }) => {
  const [image, setImage] = useState(product.image);
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  useEffect(() => {
    setImage(product.image);
  }, [product]);

  const onUploadedFile = async (event) => {
    try {
      const imageUrl = await uploadImage(
        event.target.files[0],
        setUploadProgress
      );
      setImage(imageUrl);
      onProductUpdated({ ...product, image: imageUrl });
    } catch (e) {
      console.error(e);
      alert(e.message);
    }
  };

  const onRemoveImage = () => {
    setShowDeleteModal(true);
  };

  const confirmRemoveImage = () => {
    setImage(undefined);
    onProductUpdated({ ...product, image: undefined });
    setShowDeleteModal(false);
  };

  const cancelRemoveImage = () => {
    setShowDeleteModal(false);
  };

  const onPreviewImage = () => {
    setShowModal(true);
  };
  return (
    <div className="imageEditorStyles">
      <div className="preview-image-modal-ref">
        <img
          src={
            image ||
            "  https://firebasestorage.googleapis.com/v0/b/restauradmin.appspot.com/o/images%2Fplaceholder-image.webp?alt=media&token=929c5218-99e7-4ca3-8668-93341e973be5"
          }
          alt={image ? "Vista previa de la imagen" : "No hay imagen disponible"}
        />
      </div>
      <div className="image-buttons">
        <label
          htmlFor={`file-upload-${product.id}`}
          className="file-upload-icon"
        >
          <input
            id={`file-upload-${product.id}`}
            type="file"
            onChange={onUploadedFile}
          />
          <BsUpload className="upload-text-ref"></BsUpload>
        </label>

        <BsArrowsAngleExpand
          variant="secondary"
          onClick={onPreviewImage}
          className="expand-image-icon"
        ></BsArrowsAngleExpand>

        <BsTrash
          variant="secondary"
          onClick={onRemoveImage}
          className="trash-image-icon"
        ></BsTrash>
      </div>
      <div>
        {uploadProgress >0 && (
          <div className="progress-bar">
            CARGANDO IMAGEN ...
            <div
              className="progress-bar-inner"
              style={{ width: `${uploadProgress}%` }}
            />
          </div>
        )}
      </div>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Vista Previa de la Imagen</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img
            src={image}
            alt="Vista previa de la imagen"
            className="preview-image-modal"
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showDeleteModal} onHide={cancelRemoveImage}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmar Eliminación</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          ¿Estás seguro de que deseas eliminar esta imagen?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={cancelRemoveImage}>
            Cancelar
          </Button>
          <Button variant="danger" onClick={confirmRemoveImage}>
            Eliminar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ImageEditor;
