import React, { useState } from "react";

const PriceEditor = ({ priceTypes, price, onChange }) => {
  const [inputState, setInputState] = useState({
    value: price.value,
    cursorPosition: 0,
  });

  const onChangePrice = (event) => {
    const inputValue = event.target.value;
    const newPosition = event.target.selectionStart || 0;

    setInputState({ value: inputValue, cursorPosition: newPosition });
    onChange({
      ...price,
      value: inputValue,
    });
  };

  return (
    <div className="product-price-ref">
      <span>{priceTypes[price.type]?.name}</span>
      <input
        type="text"
        className="price-input-ref"
        value={inputState.value}
        onChange={onChangePrice}
        selectionStart={inputState.cursorPosition}
        selectionEnd={inputState.cursorPosition}
      />
    </div>
  );
};

export default PriceEditor;
